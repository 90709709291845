@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Roboto', sans-serif;
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
}

@media (max-width: 768px) {
  .md\:ml-24 {
    margin-left: 0;
  }

  .md\:items-start {
    align-items: center;
  }

  .md\:justify-start {
    justify-content: center;
  }

  .md\:p-0 {
    padding: 0;
  }

  .md\:h-screen {
    height: auto;
  }

  .md\:text-left {
    text-align: center;
  }
}

.overflow-y-auto {
  overflow-y: auto;
}

.saas-card {
  position: relative;
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s, background-color 0.3s;
  height: 10rem;
  display: flex;
  flex-direction: column;
}

.saas-card:hover {
  transform: scale(1.05);
  background-color: #f5f5f5;
}

.saas-card-status {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border-radius: 9999px;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  font-weight: bold;
}

.saas-card-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.saas-card-title {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
  font-weight: bold;
  color: black;
}

.saas-card-title img {
  width: 1.75rem;
  height: 1.75rem;
  margin-right: 1rem;
  border-radius: 9999px;
}

.saas-card-description {
  color: #4a4a4a;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limite le texte à 3 lignes */
  -webkit-box-orient: vertical;
}

